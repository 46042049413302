export type AlertState = 'success' | 'danger' | ''
export interface IUserLevel {
  SYSTEM_ADMIN: string
  ADMIN_USER: string
  BUILDING_MANAGER: string
  SWEEPER: string
  SETUP_PERSON: string
  SALES_REP: string
}
export const UserLevel: IUserLevel = {
  SYSTEM_ADMIN: 'A',
  ADMIN_USER: 'A1',
  BUILDING_MANAGER: 'B',
  SWEEPER: 'C',
  SETUP_PERSON: 'F',
  SALES_REP: 'S',
}
export const Roles: Array<{ [key: string]: string }> = [
  { id: 'A', name: 'System Admin' },
  { id: 'A1', name: 'Adminユーザ' },
  { id: 'B', name: 'ビル管理者' },
  { id: 'C', name: '清掃員' },
  { id: 'F', name: 'セットアップ者' },
  { id: 'S', name: '営業担当者' },
]
export class RoleBaseChecker {
  public getListRoleByMenu(menu: string): string[] {
    const U: IUserLevel = UserLevel

    switch (menu) {
      case 'places':
        return [U.SYSTEM_ADMIN, U.SETUP_PERSON]
      case 'devices':
        return [U.SYSTEM_ADMIN, U.SETUP_PERSON]
      case 'item_groups':
        return [U.SYSTEM_ADMIN, U.SETUP_PERSON]
      case 'users':
        return [U.SYSTEM_ADMIN]
      case 'connect':
        return [U.SYSTEM_ADMIN, U.SETUP_PERSON]
      default:
        return Object.values(U)
    }
  }
  public canAccess(menu: string, role: keyof IUserLevel) {
    return this.getListRoleByMenu(menu).includes(role)
  }
}
